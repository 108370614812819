import React from 'react';
import './styles.css';
import { Link } from 'gatsby';
import Navbar from '../components/navbar/navbar';

const HowItWorks = () => {
  return (
    <>
      <Navbar title="Learn how Email Validation / Spam Checker Works? - Free Spam Score Checker" />
      <div className="container">
        <article className="article">
          <h1 className="article-title"><Link to="/how-it-works">How it Works?</Link></h1>
          <p>
            Using <Link to="/">Email Validation</Link> service is pretty easy: 
          </p>
          <br />
          <ul>
            <li>Head down to the <Link to="/">home page</Link></li>
            <li>Click on the email address appearing on the homepage to copy it to clipboard</li>
            <li>Send an email from your email account to the address you just copied</li>
            <li>Click on the "Generate Report" button on the <Link to="/">home page</Link></li>
            <li>Wait for the report to be generated</li>
            <li>Once your email is received and parse, you will see a spam score on the page along with parsed headers</li>
          </ul>
        </article>
      </div>
    </>
  );
}

export default HowItWorks;
